import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getActiveMediaBlocks,
  getActiveVisualisations,
  getVisualisationsErrorList,
  getVisualisationsLoadingList,
} from 'store/reducers/visualisations/getters';
import {
  ACTIVE_BOARD_AREA_LABEL,
  ACTIVE_BOARD_AREA_LABEL_IN_VIEW_MODE,
  CONTEXT_MENU,
  DELETE_BUTTONS,
  isCopyHotkey,
  isCutHotkey,
  isDownKeyz,
  isLeftKeyz,
  isPasteHotkey,
  isRightKeyz,
  isStepRedo,
  isStepUndo,
  isUpKeyz,
  MENU_LIST_COMPONENT,
  MENU_PAGE_LIST_COMPONENT,
  MODAL_UNIVERSAL,
  SCROLL_TO_TOP,
  SELECTOR_MENU,
  SETTINGS_BUTTON_LABEL,
  SQL_BUTTON_LABEL,
  TOOLTIP,
} from 'modules/workspace/constans';
import { useAppDispatch } from 'store';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { goToBoardUrl, PageIdParam, ProjectIdParam } from 'constants/Routes';
import { useWindowDimensions } from 'utils/hooks/screenSizeHook';
import { NotViewerWrapper } from 'shared/accessWrapper';
import { isField, isHasParentWithClassName } from 'utils/utils';
import {
  getEnabledFiltersOnPage,
  getFiltersByPageId,
  getFiltersErrorsList,
  getFiltersValuesLoadingList,
} from 'store/reducers/filters/getters';
import {
  copyToBufferAction,
  cutToBufferAction,
  moveVisualization,
  pasteFromBufferAction,
  removeBoardElementAction,
} from 'store/reducers/board/actions';
import { PageBoardSettingsSizesInterface } from 'store/reducers/projectPages/types';
import { ProjectSettingsInterface } from 'store/reducers/projectSettings/types';
import { FilterArea } from 'modules/workspace/components/FilterArea';
import { MediaBlockArea } from 'modules/workspace/components/MediaBlockArea';
import { VisualisationArea } from 'modules/workspace/components/VisualisationArea';
import { getActiveBoardElement, getActiveBoardElementInViewMode, getInfluenceFiltersMode } from 'store/reducers/board/getters';
import { useSqlRequest } from 'modules/visualisations/hooks/sqlRequest';
import { useRequestAnimation } from 'utils/hooks/requestAnimation';
import { updateDashboardComponentRenderingAction } from 'store/reducers/projectSettings/actions';
import { ContextMenuWrapper } from 'modules/settingsContainer/ContextMenuWrapper';
import { setActiveBoardElement, setActiveBoardElementInViewMode } from 'store/reducers/board';
import { setDefaultSettingsComponentAction } from 'store/reducers/visualSettings/actions';
import { colorPickerClassName } from 'modules/settingsContainer/ColorPicker/constants';
import { imagePickerClassName } from 'modules/settingsContainer/ImagePicker/constants';
import { useWorkAreaMenu } from 'modules/workspace/components/WorkAreaSpace/hook/useWorkAreaMenu';
import { getActivePageId } from 'store/reducers/projectPages/getters';
import { redoHistoryStepAction, setIsAnotherPageAction, undoHistoryStepAction } from 'store/reducers/history/actions';
import { getHasRedoHistory, getHasUndoHistory, getIsAnotherPage } from 'store/reducers/history/getters';
import { ExportType } from 'modules/settingsContainer/ContextMenuWrapper/types';
import { exportVisualisationByIdAction, updateEventSettingsAction } from 'store/reducers/visualisations/actions';
import { EventsSettingsInterface } from 'store/reducers/visualisations/types';
import { MapRecordType } from 'types/global';
import { onFilterInfluencesChange } from 'modules/settingsContainer/common/data/DefaultFilterEventSettings/constants';
import { DATE_FILTER } from 'modules/filters/Date/view/Filter/constants';

interface WorkAreaSpaceProps
  extends Pick<ProjectSettingsInterface, 'isShowWorkSpace' | 'isViewMode'>,
    PageBoardSettingsSizesInterface {
  needScale: boolean;
  isViewer: boolean;
}

export const WorkAreaSpace = ({ width, height, isViewMode, isShowWorkSpace, needScale, isViewer }: WorkAreaSpaceProps) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const activePageId = useSelector(getActivePageId);

  const { projectId } = useParams<ProjectIdParam>();
  const { pageId } = useParams<PageIdParam>();

  const visualisationsLoadingList = useSelector(getVisualisationsLoadingList);
  const filtersValuesLoading = useSelector(getFiltersValuesLoadingList);
  const visualisationsErrorsList = useSelector(getVisualisationsErrorList);
  const filtersErrorsList = useSelector(getFiltersErrorsList);
  const hasRedoHistory = useSelector(getHasRedoHistory);
  const hasUndoHistory = useSelector(getHasUndoHistory);
  const isAnotherPage = useSelector(getIsAnotherPage);
  const influenceFiltersMode = useSelector(getInfluenceFiltersMode);

  const mediaBlocks = useSelector(getActiveMediaBlocks(pageId));
  const [nonBlockedMediaBlocks, isMediaBlocksRendered] = useRequestAnimation({
    value: mediaBlocks,
    clearDependsOn: pageId,
  });

  const visualisations = useSelector(getActiveVisualisations(pageId));
  const [nonBlockedVisualisations, isVisualisationsRendered] = useRequestAnimation({
    value: visualisations,
    clearDependsOn: pageId,
  });

  const filters = useSelector(getFiltersByPageId(pageId));
  const [nonBlockedFilters, isFiltersRendered] = useRequestAnimation({
    value: filters,
    clearDependsOn: pageId,
  });

  const filtersOnPageList = useSelector(getEnabledFiltersOnPage(activePageId));

  const isDashboardComponentsRendering = useMemo(
    () => isMediaBlocksRendered === false || isVisualisationsRendered === false || isFiltersRendered === false,
    [isFiltersRendered, isMediaBlocksRendered, isVisualisationsRendered],
  );

  useEffect(() => {
    dispatch(updateDashboardComponentRenderingAction(isDashboardComponentsRendering));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDashboardComponentsRendering]);

  const activeElement = useSelector(getActiveBoardElement);
  const activeElementInViewMode = useSelector(getActiveBoardElementInViewMode);
  const activeElementId = activeElement || activeElementInViewMode;

  const { getSqlRequest, getSqlFilter, getAllSQLForExport } = useSqlRequest();

  const commonSqlRequest = useMemo(
    () => (activeElementId ? getAllSQLForExport(activeElementId) : undefined),
    [activeElementId, getAllSQLForExport],
  );

  const { width: widthDisplay, height: heightDisplay } = useWindowDimensions();

  const onFilterInfluenceChange = (data: {
    visualizationInfluences: EventsSettingsInterface;
    filterInfluences: MapRecordType<boolean>;
    isVisualization: boolean;
  }) => {
    const { visualizationInfluences, filterInfluences, isVisualization } = data;

    if (isVisualization) {
      dispatch(updateEventSettingsAction(visualizationInfluences));
    }

    if (!isVisualization) {
      onFilterInfluencesChange(filterInfluences);
    }
  };

  const onExport = (exportType: ExportType) => {
    if (activeElementId && commonSqlRequest && projectId) {
      dispatch(
        exportVisualisationByIdAction({
          projectId,
          visualizationId: activeElementId,
          data: commonSqlRequest,
          exportType,
        }),
      );
    }
    return undefined;
  };

  const { additionalMenuList, menuList, menuStepInHistoryList, exportMenu } = useWorkAreaMenu({
    activeWidgetId: activeElement,
    isViewMode,
    hasRedoHistory,
    hasUndoHistory,
    onExport,
  });

  /* TODO: what does mean 116 and 'center' : 'flex-start'?  */
  // const checkResize = useMemo(() => {
  //   return widthDisplay - 116 > width ? 'center' : 'center';
  // }, [widthDisplay, width]);

  useEffect(
    () => {
      if (isAnotherPage?.redirectPageId !== pageId && isAnotherPage.isAnother) {
        navigate(goToBoardUrl(projectId, isAnotherPage.redirectPageId));
        dispatch(
          setIsAnotherPageAction({
            value: {
              isAnother: false,
              redirectPageId: '',
            },
          }),
        );
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, navigate, pageId, isAnotherPage],
  );

  useEffect(() => {
    const callback = (e: KeyboardEvent) => {
      const isWorkAreaInFocus = document.getElementsByClassName(ACTIVE_BOARD_AREA_LABEL).length !== 0;

      const eTagName = (e.target as Element)?.tagName;

      if (!isField(eTagName)) {
        if (isWorkAreaInFocus && isCopyHotkey(e)) {
          dispatch(copyToBufferAction());
        }
        if (isWorkAreaInFocus && isCutHotkey(e)) {
          dispatch(cutToBufferAction());
        }
        if (isWorkAreaInFocus && DELETE_BUTTONS.includes(e.key)) {
          dispatch(removeBoardElementAction());
        }
        if (isWorkAreaInFocus && isUpKeyz(e)) {
          e.preventDefault();
          dispatch(moveVisualization({ typeMove: 'top' }));
        }
        if (isWorkAreaInFocus && isDownKeyz(e)) {
          e.preventDefault();
          dispatch(moveVisualization({ typeMove: 'down' }));
        }
        if (isWorkAreaInFocus && isLeftKeyz(e)) {
          e.preventDefault();
          dispatch(moveVisualization({ typeMove: 'left' }));
        }
        if (isWorkAreaInFocus && isRightKeyz(e)) {
          e.preventDefault();
          dispatch(moveVisualization({ typeMove: 'right' }));
        }
        if (isPasteHotkey(e)) {
          dispatch(pasteFromBufferAction());
        }
      }

      if (isStepRedo(e)) {
        dispatch(redoHistoryStepAction());
      }
      if (isStepUndo(e)) {
        dispatch(undoHistoryStepAction());
      }
    };

    document.addEventListener('keydown', callback);

    return () => document.removeEventListener('keydown', callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const element = document.getElementById(SCROLL_TO_TOP);

    if (element) {
      element.scrollIntoView();
    }
  }, [pathname]);

  const scale = useMemo(() => (needScale ? Number(widthDisplay / width) : 1), [needScale, widthDisplay, width]);
  const filtersRowHeight = useMemo(() => `${filtersOnPageList.length ? 100 : 50}px`, [filtersOnPageList]);

  const scaleContainerOptions = useMemo(
    () => ({
      transform: `scale(${scale})`,
      height: `calc((${heightDisplay}px - ${filtersRowHeight}) / ${scale})`,
    }),
    [filtersRowHeight, heightDisplay, scale],
  );

  const defaultContainerOptions = useMemo(
    () => ({
      width: '100%',
      height: '100%',
      maxHeight: `calc(100vh - ${filtersRowHeight})`,
    }),
    [filtersRowHeight],
  );

  useEffect(() => {
    if (influenceFiltersMode) {
      return;
    }

    const callback = (e: MouseEvent) => {
      const menu = document.getElementById(ACTIVE_BOARD_AREA_LABEL),
        settingsButton = document.getElementById(SETTINGS_BUTTON_LABEL),
        sqlButton = document.getElementById(SQL_BUTTON_LABEL),
        clickedElement = document.getElementsByClassName(ACTIVE_BOARD_AREA_LABEL)[0],
        clickedElementInViewMode = document.getElementsByClassName(ACTIVE_BOARD_AREA_LABEL_IN_VIEW_MODE)[0],
        clickedElementTooltip = document.getElementsByClassName(TOOLTIP)[0];

      const isClickToItem = [
        menu,
        settingsButton,
        clickedElementTooltip,
        sqlButton,
        clickedElement,
        clickedElementInViewMode,
      ].some((item) => item && item.contains(e.target as Node));

      const isClickToSelectorItem = e.target ? isHasParentWithClassName(e.target as Element, SELECTOR_MENU) : false;

      const isClickToModal = e.target ? isHasParentWithClassName(e.target as Element, MODAL_UNIVERSAL) : false;

      const isClickToContextMenuItem = e.target ? isHasParentWithClassName(e.target as Element, CONTEXT_MENU) : false;

      const isClickToDateFilter = e.target ? isHasParentWithClassName(e.target as Element, DATE_FILTER) : false;

      const isClickToColorPicker = e.target ? isHasParentWithClassName(e.target as Element, colorPickerClassName) : false;

      const isClickToImagePicker = e.target ? isHasParentWithClassName(e.target as Element, imagePickerClassName) : false;

      const needDeactivateBoardElement = !(
        isClickToSelectorItem ||
        isClickToContextMenuItem ||
        isClickToItem ||
        isClickToDateFilter ||
        isClickToModal ||
        isClickToColorPicker ||
        isClickToImagePicker
      );

      if (needDeactivateBoardElement) {
        const targetElement = e.target as HTMLElement;
        const menuElement = document.getElementById(MENU_LIST_COMPONENT);
        const pageListComponent = document.getElementById(MENU_PAGE_LIST_COMPONENT);
        if (
          (menuElement && menuElement.contains(targetElement)) ||
          (pageListComponent && pageListComponent.contains(targetElement))
        ) {
          return;
        }

        dispatch(setActiveBoardElement(null));

        dispatch(setActiveBoardElementInViewMode(null));
        dispatch(setDefaultSettingsComponentAction());
      }
    };

    document.addEventListener('mousedown', callback);
    document.addEventListener('contextmenu', callback);
    return () => {
      document.removeEventListener('mousedown', callback);
      document.removeEventListener('contextmenu', callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [influenceFiltersMode]);

  const containerOptions = useMemo(
    () => (needScale ? scaleContainerOptions : defaultContainerOptions),
    [defaultContainerOptions, needScale, scaleContainerOptions],
  );

  const notViewerContainerOptions = useMemo(() => (!isViewer ? { maxWidth: 'calc(100vw - 116px)' } : {}), [isViewer]);

  return (
    <FlexContainer width="100%" height="100%">
      <ContextMenuWrapper
        menuList={menuList}
        additionalMenuList={additionalMenuList}
        isViewer={isViewer}
        activeWidgetId={activeElement}
        widgetIdInViewerMode={!!activeElementId}
        sqlRequest={commonSqlRequest}
        menuStepInHistoryList={menuStepInHistoryList}
        exportMenu={exportMenu}
      >
        <FlexContainer
          {...containerOptions}
          {...notViewerContainerOptions}
          overflow="auto"
          backgroundColor="transparent"
          transformOrigin={widthDisplay - 116 > width ? 'center top' : 'left top'}
          display="block"
        >
          <FlexContainer
            id={SCROLL_TO_TOP}
            width={`${width}px`}
            height={`${height}px`}
            border={isShowWorkSpace ? `1px dashed var(${ColorVarsEnum.Level_2})` : '1px dashed transparent'}
            position="relative"
            margin="0 auto"
          >
            {nonBlockedVisualisations.map((visualisation) => {
              const { id } = visualisation;

              return (
                <VisualisationArea
                  data={visualisation}
                  key={id}
                  scale={scale}
                  isShowMode={isViewMode || isViewer}
                  isActive={activeElement === id}
                  isActiveInViewMode={activeElementInViewMode === id}
                  sqlRequest={getSqlRequest(id)}
                  isLoading={!!visualisationsLoadingList[id]}
                  isError={visualisationsErrorsList[id]}
                  isInfluenceEditing={influenceFiltersMode}
                  onInfluenceChange={onFilterInfluenceChange}
                />
              );
            })}
            {nonBlockedMediaBlocks.map((mediaBlock) => {
              const { id } = mediaBlock;

              return (
                <MediaBlockArea
                  data={mediaBlock}
                  key={id}
                  scale={scale}
                  isShowMode={isViewMode || isViewer}
                  isActive={activeElement === id}
                  sqlRequest={getSqlRequest(id)}
                  isLoading={!!visualisationsLoadingList[id]}
                  isError={visualisationsErrorsList[id]}
                  isInfluenceEditing={influenceFiltersMode}
                  onInfluenceChange={onFilterInfluenceChange}
                />
              );
            })}
            {!!nonBlockedFilters.length &&
              nonBlockedFilters.map((filter) => {
                const { id, hint } = filter;
                if (!hint.isShow) return null;

                return (
                  <FilterArea
                    data={filter}
                    key={id}
                    scale={scale}
                    isShowMode={isViewMode || isViewer}
                    isActive={activeElement === id}
                    whereQuery={getSqlFilter(id)}
                    isLoading={!!filtersValuesLoading[id]}
                    isError={filtersErrorsList[id]}
                    isInfluenceEditing={influenceFiltersMode}
                    onInfluenceChange={onFilterInfluenceChange}
                  />
                );
              })}
          </FlexContainer>
        </FlexContainer>

        {isShowWorkSpace && (
          <NotViewerWrapper>
            <FlexContainer position="absolute" top="112px" margin="0 auto" left="50%" transform="translate(-50%)">
              <PrimaryTextSpan textAlign="center" fontSize="10px" lineHeight="13px" color={`var(${ColorVarsEnum.Level_4})`}>
                Рабочая область: {width} x {height}
              </PrimaryTextSpan>
            </FlexContainer>
          </NotViewerWrapper>
        )}
      </ContextMenuWrapper>
    </FlexContainer>
  );
};
