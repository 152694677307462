import React from 'react';
import { useGetActiveFilter } from 'modules/filters/hooks/getActiveFilter';
import {
  onFilterElementsChange,
  onModelIdChange,
  onRealDataChange,
  onSqlDataChange,
} from 'modules/settingsContainer/common/data/DefaultFilterEventSettings/constants';
import { LimitSettings } from 'modules/settingsContainer/common/data/LimitSettings';
import { onLimitChange } from 'modules/filters/Single/settings/DataTab/constants';
import { FilterNameSettingsInterface } from 'store/reducers/filters/types';
import { FilterIncisionsSettings } from 'modules/settingsContainer/FIlterIncisionsSettings';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { sqlParser } from 'utils/SQL/genereteAst';
import { RealDataSettings } from 'modules/settingsContainer/common/data/RealDataSettings';
import { ModelSelectorSettings } from 'modules/settingsContainer/common/ModelSelectorSettings';
import { SqlFilterSettings } from 'modules/settingsContainer/common/data/SqlFilterSettings';
import { SqlDataRequestInterface } from 'types/store';
import { NameFilterSettings } from 'modules/settingsContainer/NameFilterSettings';
import { updateFilterAction } from 'store/reducers/filters/actions';
import { useAppDispatch } from 'store';

export const DataTab = () => {
  const dispatch = useAppDispatch();
  const { data, tableFields, modelIdValue } = useGetActiveFilter({ type: 'multiple' });
  const { modelMetaData } = useDataSettingsMetric(data.modelId);

  const { isRealData, nameSettings, fictionalData, limit, isMultipleMode, sqlData } = data;
  const { fieldName } = nameSettings;

  const onSaveSql = ({ incisionRequest, filterAndGroupRequest }: SqlDataRequestInterface) => {
    const isValidIncisionAst = !incisionRequest ? true : sqlParser.astify(`SELECT ${incisionRequest}`);
    const isValidFilterAst = sqlParser.astify(`SELECT * ${filterAndGroupRequest}`);

    if (isValidIncisionAst && isValidFilterAst) {
      const fieldNameMatch = tableFields.some((field) => field.value === incisionRequest);

      if (fieldNameMatch) {
        onFilterElementsChange({ fictionalData, nameSettings: { ...nameSettings, fieldName: incisionRequest } });
      }

      if (!incisionRequest && incisionRequest !== fieldName) {
        onFilterElementsChange({ fictionalData, nameSettings: { ...nameSettings, fieldName: null } });
      }

      onSqlDataChange({
        sqlData: {
          incisionRequest,
          filterAndGroupRequest,
        },
      });
    }
  };

  const onNameSettingsChange = (nameSettings: FilterNameSettingsInterface['nameSettings']) =>
    dispatch(updateFilterAction({ nameSettings }));

  return (
    <>
      <RealDataSettings value={isRealData} onChange={onRealDataChange} />
      <ModelSelectorSettings value={modelIdValue} onChange={onModelIdChange} />
      <FilterIncisionsSettings
        onChange={onFilterElementsChange}
        isRealData={isRealData}
        options={tableFields}
        value={{ fictionalData, nameSettings }}
        modelMetaData={modelMetaData}
        onChangeSqlData={onSaveSql}
        data={data}
      />
      <NameFilterSettings isRealData={isRealData} value={nameSettings} onChange={onNameSettingsChange} />
      <SqlFilterSettings sqlData={sqlData} onSave={onSaveSql} modelMetaData={modelMetaData} fieldName={fieldName} />
      <LimitSettings value={limit} onChange={onLimitChange} width="auto" />
    </>
  );
};
