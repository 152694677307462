import { BarSeriesOption, LineSeriesOption } from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import {
  createBarPropertiesData,
  createTooltipFormatter,
  defaultGridDimension,
  getIncisionAxisConfigWithGridDimensions,
  getLegendConfigWithGridDimensions,
  getMinMaxValues,
  onActiveIncisionIdChange,
  onActiveNextIncisionIdChange,
  onDeleteFiltersForDrillDownVisualisation,
} from 'modules/visualisations/common/constants';
import { formatResult } from 'modules/visualisations/common/onChangeFunctions';
import { SingleIncisionLayout } from 'modules/visualisations/components/SingleIncisionLayout';
import { WrappedReactECharts } from 'modules/visualisations/components/WrappedReactECharts';
import { useActiveIncision } from 'modules/visualisations/hooks/activeIncision';
import { useManualResize } from 'modules/visualisations/hooks/manualResize';
import { useDataZoom } from 'modules/visualisations/hooks/useDataZoom';
import { useIncisionColors } from 'modules/visualisations/hooks/useIncisionColors';
import { useProperties } from 'modules/visualisations/hooks/useProperties';
import { useVisualisation } from 'modules/visualisations/hooks/visualisation';
import {
  onDataZoomHorizontalStartAndEndSettingsChange,
  onDataZoomVerticalStartAndEndSettingsChange,
} from 'modules/visualisations/LineAndBar/settings/ViewTab/constants';
import {
  BarAndLineTypesEnum,
  BarTypesEnum,
  determineColorStrategy,
  getBarGraphGridDimensions,
  getBarPositionLabel,
  getColorByType,
  getFontOption,
  getIncisionColorSettings,
  getIndicatorAxisWithGridDimensions,
  getLineAndBarData,
  getLinePositionLabel,
  getValueColorSettings,
  processValue,
} from 'modules/visualisations/LineAndBar/visualisation/constants';
import {
  LineAndBarDataResultCallback,
  LineAndBarEChartsOption,
  LineAndBarNode,
} from 'modules/visualisations/LineAndBar/visualisation/types';
import { VisualisationOriginInterface } from 'modules/workspace/components/VisualisationArea/types';
import { memo, useCallback, useMemo } from 'react';
import {
  ColorStrategyEnum,
  defaultLineAndBarDataSettings,
  getVisualisationFieldName,
  LineAndBarTypeIndicatorEnum,
} from 'store/reducers/visualisations/constants';
import {
  BarValuePositionType,
  ColorAndImageByEnum,
  FormattingInterface,
  LineAndBarVisualisationType,
} from 'store/reducers/visualisations/types';
import { DefaultAxisInterface, GridDimensionsInterface, ItemValueColor } from 'types/echarts';
import { TopAndBottomType } from 'types/styles';
import { calculateGridDimension, initialDimensions } from 'utils/generateConfigGraphic';
import { useFormat } from 'modules/visualisations/hooks/useFormat';
import { useElementGlobalDesign } from 'modules/visualisations/hooks/useElementGlobalDesign';
import { ColorSettingsIndicator } from 'types/types';
import { useColorsLegendData } from 'utils/hooks/visualisation/useColorsLegendData';
import { PositionSettingEnum } from 'types/store';

export const LineAndBarVisualisationComponent: VisualisationOriginInterface<LineAndBarVisualisationType> = ({
  data,
  sqlRequest,
}) => {
  const {
    id,
    dataSettings,
    viewSettings: {
      elementDesign,
      isVisible,
      dataZoomVerticalStartAndEnd,
      dataZoomHorizontalStartAndEnd,
      description,
      header,
      axisIncisionSettings,
      axisIndicatorSettings,
      axisAdditionalIndicatorSettings,
      horizontalZoom,
      verticalZoom,
      showTips,
      legendSettings,
      shadowSettings,
      incisionSelectorPosition,
      visualisationPaddings: {
        isShow: visualisationPaddingsIsShow,
        paddingsVisualisation: { topPadding, rightPadding, leftPadding, bottomPadding },
      },
    },
    sqlData,
    events,
  } = data;

  const {
    properties: propertiesGlobal,
    colorStrategyType,
    colorSettingsIndicator: { elementColor: elementIndicatorGlobalColor },
    colorSettingsIncision: { elementColor: elementIncisionGlobalColor },
    propertiesIncisionColorSettings: { elementColor: valueIncisionGlobalColor },
    propertiesIndicatorColorSettings: { elementColor: valueIndicatorGlobalColor },
  } = elementDesign;

  const {
    colorSettingsGlobal: { elementColorBy: elementGlobalColorBy },
    propertiesColorGlobal: { elementColorBy: fontGlobalColorBy },
  } = useElementGlobalDesign(elementDesign);

  const propertiesLegend = legendSettings.properties;

  const { isDrillDown } = dataSettings,
    {
      shadowColorSettings: { shadowColorBy },
    } = shadowSettings;

  const indicatorsAndIndicatorsStackSum = useMemo(
    () => [...dataSettings.indicators, ...dataSettings.indicatorsStackSum],
    [dataSettings.indicators, dataSettings.indicatorsStackSum],
  );

  const propertiesData = useMemo(
    () => createBarPropertiesData(indicatorsAndIndicatorsStackSum),
    [indicatorsAndIndicatorsStackSum],
  );

  const properties = useProperties(propertiesData);

  const indicatorsElementsColorsBy = useMemo(
    () =>
      dataSettings.indicators.map(
        ({
          settings: {
            elementSettings: { colorBySettings },
          },
        }) => colorBySettings.elementColorBy,
      ),
    [dataSettings.indicators],
  );

  const indicatorsValuesColorsBy = useMemo(
    () =>
      dataSettings.indicators.map(
        ({
          settings: {
            showValue: { colorBySettings },
          },
        }) => colorBySettings.elementColorBy,
      ),
    [dataSettings.indicators],
  );

  const incisionValuesColorsBy = useMemo(
    () =>
      dataSettings.incisions.map(
        ({ colorBySettingsValueAndElement: { colorBySettingsValue } }) => colorBySettingsValue.elementColorBy,
      ),
    [dataSettings.incisions],
  );

  const incisionElementColorsBy = useMemo(
    () =>
      dataSettings.incisions.map(
        ({ colorBySettingsValueAndElement: { colorBySettingsElement } }) => colorBySettingsElement.elementColorBy,
      ),
    [dataSettings.incisions],
  );

  const {
    visualisationNormalizedValues,
    updateFilter,
    eChartRef,
    getColorByValue,
    getColorBySpecificValue,
    getVisualisationColorsAndImagesData,
    isColorByCondition,
    enabledFilters,
  } = useVisualisation({
    sqlData,
    id,
    dataSettings,
    activeIncisionId: dataSettings?.activeIncisionId,
    colorsBy: [
      shadowColorBy,
      elementGlobalColorBy,
      fontGlobalColorBy,
      ...indicatorsValuesColorsBy,
      ...indicatorsElementsColorsBy,
      ...incisionValuesColorsBy,
      ...incisionElementColorsBy,
    ],
    events,
    limit: dataSettings.limit,
    intervalRandomData: dataSettings.minAndMax,
    sqlRequest,
  });

  const formattingDataVariables = useMemo(
    () =>
      indicatorsAndIndicatorsStackSum.reduce<Record<string, FormattingInterface>>(
        (indicatorStackSum, { name, settings: { formatting } }) => ({
          ...indicatorStackSum,
          [name]: formatting,
        }),
        {},
      ),
    [indicatorsAndIndicatorsStackSum],
  );

  const { formatting } = useFormat(formattingDataVariables);

  const activeIncision = useActiveIncision({
    activeIncisionId: dataSettings.activeIncisionId,
    defaultIncision: defaultLineAndBarDataSettings.incisions[0],
    incisions: dataSettings.incisions,
  });

  const {
    data: {
      colorBySettingsValueAndElement: {
        colorBySettingsValue: valueColorBySettingsIncision,
        colorBySettingsElement: elementColorBySettingsIncision,
      },
    },
  } = activeIncision;

  const { getColorValues, activeThemeSchema, defaultThemePalette, defaultColor } = useColorValues();

  const lineAndBarData = useMemo(
    () => getLineAndBarData(dataSettings, visualisationNormalizedValues),
    [dataSettings, visualisationNormalizedValues],
  );

  const getParamsIncisionColor = useCallback(
    (index: number) => ({
      name: String(lineAndBarData?.data[activeIncision.fieldName]?.[index]) || '',
      componentType: '',
      componentSubType: '',
      componentIndex: 0,
      dataIndex: 0,
      data: undefined,
      value: undefined,
      $vars: [],
    }),
    [activeIncision.fieldName, lineAndBarData?.data],
  );

  const getGlobalIncisionColorElement = useIncisionColors({
    activeIncisionSum: lineAndBarData?.activeIncisionSum,
    activeIncision,
    colors: elementIncisionGlobalColor,
  });

  useManualResize({ eChartRef: eChartRef.current, deps: [description, header, dataSettings.incisions.length] });

  /* TODO: combine getItem Value Color and getItem Color */

  const getItemValueColor = useCallback(
    (
      params: ItemValueColor & { alias: string; defaultColor?: string },
      getColorFunction: ({
        value,
        indicatorName,
        alias,
        defaultColor,
      }: {
        value: string | number | null;
        indicatorName: string;
        alias: string;
        defaultColor?: string;
      }) => string,
    ) => {
      const { data, dataIndex, seriesName, alias } = params;

      let value: string | number | null | undefined = data as number;

      if (isColorByCondition(alias)) {
        value = getVisualisationColorsAndImagesData(alias)[dataIndex];
      }

      return getColorFunction({ value, indicatorName: seriesName || '', alias, defaultColor });
    },
    [isColorByCondition, defaultColor, getVisualisationColorsAndImagesData],
  );

  const getIndicatorItemValueColor = useCallback(
    (params: ItemValueColor & { alias: string; defaultColor?: string }) => {
      return getItemValueColor(params, getColorByValue);
    },
    [getItemValueColor, getColorByValue],
  );

  const getIncisionItemValueColor = useCallback(
    (params: ItemValueColor & { alias: string }) => {
      return getItemValueColor(params, getColorByValue);
    },
    [getItemValueColor, getColorByValue],
  );

  const getItemGlobalValueColor = useCallback(
    (params: ItemValueColor) => {
      const alias = fontGlobalColorBy.byCondition.alias;
      return getItemValueColor({ ...params, alias }, getColorBySpecificValue);
    },
    [getItemValueColor, fontGlobalColorBy.byCondition.alias, getColorBySpecificValue],
  );

  const getIncisionColorValue = useIncisionColors({
    activeIncisionSum: lineAndBarData?.activeIncisionSum,
    activeIncision,
    colors: activeIncision.data.colorBySettingsValueAndElement.colorBySettingsElement.elementColor,
  });

  const getItemGlobalColor = useMemo(
    () => (params: CallbackDataParams) => {
      const { data: dataValue, seriesName, dataIndex } = params as LineAndBarNode,
        alias = elementGlobalColorBy.byValueSpecific.alias;

      let value: string | number | null | undefined = dataValue?.value as number;

      if (isColorByCondition(alias)) {
        value = getVisualisationColorsAndImagesData(alias)[dataIndex];
      }
      const paramsIncisionColor = getParamsIncisionColor(dataIndex);

      const elementColorBySettings = dataSettings.indicators?.find(({ name }) => name === seriesName)?.settings.elementSettings
        .colorBySettings as ColorSettingsIndicator;

      const {
        isActive: isActiveIndicatorElementColor,
        alias: aliasIndicatorElementColor,
        type: elementIndicatorColorByType,
        color: elementIndicatorColor,
      } = getValueColorSettings(elementColorBySettings);

      const {
        isActive: isActiveIncisionElementColor,
        alias: aliasIncisionElementColor,
        type: valueIncisionElementByType,
      } = getIncisionColorSettings(elementColorBySettingsIncision);

      const itemIndicatorElementColor = getIndicatorItemValueColor({
        data: value,
        dataIndex,
        seriesName,
        alias: aliasIndicatorElementColor,
        defaultColor,
      });

      const colorIndicatorBy = getColorByType({
        itemColor: itemIndicatorElementColor,
        type: elementIndicatorColorByType,
        defaultColor: getColorValues(elementIndicatorColor),
      });

      const colorIncisionBy = getColorByType({
        itemColor: getIncisionItemValueColor({ data: value, alias: aliasIncisionElementColor, dataIndex, seriesName }),
        defaultColor: getIncisionColorValue && getIncisionColorValue(paramsIncisionColor),
        type: valueIncisionElementByType,
      });

      const globalElementIndicatorColor = getColorBySpecificValue({
        value,
        indicatorName: seriesName || '',
        alias,
        defaultColor,
      });

      const itemGlobalElementColor =
        colorStrategyType === ColorStrategyEnum.ByIndicator
          ? getColorValues(elementIndicatorGlobalColor)
          : getGlobalIncisionColorElement && getGlobalIncisionColorElement(paramsIncisionColor);

      const colorGlobal =
        elementGlobalColorBy.type === ColorAndImageByEnum.Default ? itemGlobalElementColor : globalElementIndicatorColor;

      const colorStrategy = determineColorStrategy({
        isActiveIndicatorColor: isActiveIndicatorElementColor,
        isActiveIncisionColor: isActiveIncisionElementColor,
        colorStrategyType,
        colorIndicatorBy,
        colorIncisionBy,
        colorGlobal,
      });

      return colorStrategy || defaultColor;
    },

    [
      colorStrategyType,
      dataSettings.indicators,
      defaultColor,
      elementColorBySettingsIncision,
      elementGlobalColorBy.byValueSpecific.alias,
      elementGlobalColorBy.type,
      elementIndicatorGlobalColor,
      getColorBySpecificValue,
      getColorValues,
      getGlobalIncisionColorElement,
      getIncisionColorValue,
      getIncisionItemValueColor,
      getIndicatorItemValueColor,
      getParamsIncisionColor,
      getVisualisationColorsAndImagesData,
      isColorByCondition,
    ],
  );

  const getGlobalIncisionColorValue = useIncisionColors({
    activeIncisionSum: lineAndBarData?.activeIncisionSum,
    activeIncision,
    colors: valueIncisionGlobalColor,
  });

  const colorsLegendData = useColorsLegendData({
    elementGlobalColorBy,
    elementIndicatorGlobalColor,
    indicators: dataSettings.indicators,
  });

  const lineAndBarDataResult = useCallback(
    <T extends BarValuePositionType & TopAndBottomType>({
      isBar,
      seriesName,
      typeIndicator,
      position,
      isHorizontalOrientation,
      isPileLabel,
      valueColorBySettings,
    }: LineAndBarDataResultCallback<T>) =>
      lineAndBarData?.data[seriesName]?.map((value, index) => {
        const {
          isActive: isActiveIndicatorValueColor,
          alias: aliasIndicatorValueColor,
          type: valueIndicatorColorByType,
          color: valueIndicatorColor,
        } = getValueColorSettings(valueColorBySettings);

        const {
          isActive: isActiveIncisionValueColor,
          alias: aliasIncisionValueColor,
          type: valueIncisionColorByType,
        } = getIncisionColorSettings(valueColorBySettingsIncision);

        const { dataParams, positiveNumber } = processValue({ value, index, seriesName });
        const itemIndicatorValueColor = getIndicatorItemValueColor({ ...dataParams, alias: aliasIndicatorValueColor });

        const paramsIncisionColor = getParamsIncisionColor(index);

        const colorIndicatorBy = getColorByType({
          itemColor: itemIndicatorValueColor,
          type: valueIndicatorColorByType,
          defaultColor: getColorValues(valueIndicatorColor),
        });

        const colorIncisionBy = getColorByType({
          itemColor: getIncisionItemValueColor({ ...dataParams, alias: aliasIncisionValueColor }),
          defaultColor: getIncisionColorValue && getIncisionColorValue(paramsIncisionColor),
          type: valueIncisionColorByType,
        });

        const itemGlobalValueColor =
          colorStrategyType === ColorStrategyEnum.ByIndicator
            ? getColorValues(valueIndicatorGlobalColor)
            : getGlobalIncisionColorValue && getGlobalIncisionColorValue(paramsIncisionColor);

        const colorGlobal =
          fontGlobalColorBy.type === ColorAndImageByEnum.Default ? itemGlobalValueColor : getItemGlobalValueColor(dataParams);

        const colorStrategy = determineColorStrategy({
          isActiveIndicatorColor: isActiveIndicatorValueColor,
          isActiveIncisionColor: isActiveIncisionValueColor,
          colorStrategyType,
          colorIndicatorBy,
          colorIncisionBy,
          colorGlobal,
        });

        /* TODO: needs refactoring */
        const isTypeIndicator = typeIndicator === LineAndBarTypeIndicatorEnum.INDICATOR;

        const color = isTypeIndicator
          ? colorStrategy || getColorValues(properties[seriesName]?.fontColor) || activeThemeSchema[ColorVarsEnum.Level_1]
          : typeIndicator === LineAndBarTypeIndicatorEnum.INDICATOR_STACK_SUM
          ? getColorValues(properties[seriesName]?.fontColor) || defaultThemePalette[0]
          : null;

        const indicatorStackPosition = isTypeIndicator
          ? position
          : dataSettings.rotateTo90
          ? PositionSettingEnum.FLEXSTART
          : PositionSettingEnum.FLEXEND;

        return {
          value: value,
          label: isBar
            ? !isPileLabel
              ? {
                  ...getBarPositionLabel(positiveNumber, isHorizontalOrientation, dataSettings.rotateTo90)[
                    indicatorStackPosition
                  ],
                  color,
                }
              : {
                  color,
                  position: ['50%', dataSettings.rotateTo90 ? '50%' : -15],
                  align: 'center',
                  distance: 5,
                  verticalAlign: 'middle',
                }
            : { ...getLinePositionLabel(dataSettings.rotateTo90, positiveNumber, isHorizontalOrientation)[position], color },
        };
      }),
    [
      lineAndBarData?.data,
      valueColorBySettingsIncision,
      getIndicatorItemValueColor,
      getParamsIncisionColor,
      getColorValues,
      getIncisionItemValueColor,
      getIncisionColorValue,
      colorStrategyType,
      valueIndicatorGlobalColor,
      getGlobalIncisionColorValue,
      fontGlobalColorBy.type,
      getItemGlobalValueColor,
      properties,
      activeThemeSchema,
      defaultThemePalette,
      dataSettings.rotateTo90,
    ],
  );

  const barType = dataSettings.barType;
  const isPile = barType.activePile && barType.type === BarTypesEnum.Pile;

  const fontColorLegend = propertiesLegend.fontColor;

  const series = useMemo<Array<LineSeriesOption | BarSeriesOption>>(() => {
    const lastBarSeriesIndex = dataSettings.indicators.reduce(
      (
        lastIndex,
        {
          settings: {
            elementSettings: { type },
          },
        },
        currentIndex,
      ) => (type === BarAndLineTypesEnum.Bar ? currentIndex : lastIndex),
      0,
    );

    return indicatorsAndIndicatorsStackSum.map(
      (
        {
          settings: {
            nameFromDatabase,
            additionalIndicators,
            elementSettings: {
              type,
              parameters,
              parametersBar: { barWidth, barMinWidth, barMaxWidth, stackNumber, stackOffset },
              colorBySettings,
            },
            showValue,
          },
          fieldName,
          name,
          type: typeIndicator,
        },
        index,
      ) => {
        const isBar = type === BarAndLineTypesEnum.Bar;
        const isTypeIndicator = typeIndicator === LineAndBarTypeIndicatorEnum.INDICATOR;

        const seriesName = getVisualisationFieldName({ name, nameFromDatabase, fieldName }),
          isShowSum = dataSettings.showSum.isShow && index === lastBarSeriesIndex;

        const sameOption = {
          markPoint: {
            symbol: 'circle',
            symbolSize: 200,
            symbolOffset: [13, 13],
            symbolRotate: 23,
          },
          stackStrategy: 'samesign',
          data: lineAndBarDataResult({
            typeIndicator,
            isBar,
            seriesName,
            position: showValue.position as BarValuePositionType & TopAndBottomType,
            isHorizontalOrientation: showValue.orientation === 'horizontal',
            isPileLabel: isPile && dataSettings.showSum.isShow,
            valueColorBySettings: showValue.colorBySettings,
          }),
        };

        const labelOption = getFontOption(properties, name, propertiesGlobal, isTypeIndicator);

        if (isBar) {
          const pileLabel: BarSeriesOption['label'] =
            isPile && dataSettings.showSum.isShow
              ? {
                  show: isShowSum,
                  formatter: (params) => {
                    if (lineAndBarData.activeIncisionSum) {
                      return String(lineAndBarData.activeIncisionSum?.[params.name]);
                    }

                    return params.value === 0 ? '' : `${params.value}${barType.type === BarTypesEnum.Nominated ? ' %' : ''}`;
                  },
                  rotate: dataSettings.showSum.orientation === 'horizontal' ? 0 : 90,
                }
              : {};
          const formattingFunction = formatting[name];

          return {
            name: seriesName,
            type,
            barWidth: formatResult(barWidth) || undefined,
            barMaxWidth: formatResult(barMaxWidth) || undefined,
            barMinWidth: formatResult(barMinWidth) || undefined,
            barGap: `${stackOffset}%` || undefined,
            yAxisIndex: !dataSettings.rotateTo90 ? (additionalIndicators ? 1 : 0) : 0,
            xAxisIndex: dataSettings.rotateTo90 ? (additionalIndicators ? 1 : 0) : 0,
            stack: String(stackNumber),
            label: {
              ...labelOption,
              backgroundColor: showValue.position !== 'outside' ? 'inherit' : 'transparent',
              show: showValue.isShow,
              rotate: showValue.orientation === 'horizontal' ? 0 : 90,
              formatter: (data) => {
                const isEmpty = data.value === 0;

                if (isEmpty) {
                  return '';
                }

                if (formatting && formattingFunction) {
                  return formattingFunction?.(data.value as number | string);
                }

                return `${data.value}${barType.type === BarTypesEnum.Nominated ? ' %' : ''}`;
              },
              ...pileLabel,
            },
            itemStyle: {
              color: isTypeIndicator ? getItemGlobalColor : 'transparent',
            },
            ...sameOption,
          } as BarSeriesOption;
        }

        const formattingFunction = formatting[name];

        const areaStyle = parameters.areaOpacity ? { areaStyle: { opacity: parameters.areaOpacity / 100 } } : undefined;

        return {
          name: seriesName,
          type,
          yAxisIndex: !dataSettings.rotateTo90 && additionalIndicators ? 1 : 0,
          xAxisIndex: dataSettings.rotateTo90 && additionalIndicators ? 1 : 0,
          label: {
            ...labelOption,
            formatter: (data) => {
              const isEmpty = data.value === 0;

              if (isEmpty) {
                return '';
              }

              if (formatting && formattingFunction) {
                return formattingFunction?.(data.value as number | string);
              }

              return data.value;
            },
            show: showValue.isShow,
            rotate: showValue.orientation === 'horizontal' ? 0 : 90,
          },
          ...areaStyle,
          symbolSize: parameters.dotWidth,
          symbol: 'circle',
          lineStyle: {
            width: parameters.lineWidth,
            type: parameters.isDotted ? 'dashed' : 'solid',
          },
          smooth: parameters.lineType === 'smooth',
          step: parameters.lineType === 'stepByStep' ? 'middle' : undefined,
          itemStyle: {
            color: isTypeIndicator
              ? !colorBySettings.isActive
                ? getColorValues(elementIndicatorGlobalColor) || defaultColor
                : getColorValues(colorBySettings.elementColor) || defaultColor
              : 'transparent',
          },
          ...sameOption,
        } as LineSeriesOption;
      },
    );
  }, [
    dataSettings.indicators,
    dataSettings.showSum.isShow,
    dataSettings.showSum.orientation,
    dataSettings.rotateTo90,
    indicatorsAndIndicatorsStackSum,
    lineAndBarDataResult,
    isPile,
    properties,
    propertiesGlobal,
    formatting,
    getColorValues,
    elementIndicatorGlobalColor,
    defaultColor,
    getItemGlobalColor,
    lineAndBarData.activeIncisionSum,
    barType.type,
  ]);

  const xAxisValue = useMemo(
    () =>
      getIncisionAxisConfigWithGridDimensions({
        axisIncisionSettings: axisIncisionSettings,
        fieldName: activeIncision.fieldName,
        activeThemeSchema,
        rotateTo90: dataSettings.rotateTo90,
        data: lineAndBarData.data[activeIncision.fieldName],
      }),
    [activeThemeSchema, activeIncision.fieldName, lineAndBarData.data, axisIncisionSettings, dataSettings.rotateTo90],
  );

  const isNominated = useMemo(() => barType.type === BarTypesEnum.Nominated, [barType.type]);

  const minAndMaxValues = useMemo(
    () =>
      getMinMaxValues({
        settings: dataSettings.minAndMax,
        isNominated,
        activeIncisionSum: lineAndBarData.activeIncisionSum,
      }),
    [dataSettings.minAndMax, lineAndBarData.activeIncisionSum, isNominated],
  );

  const minAndMaxAdditionalValues = useMemo(
    () =>
      getMinMaxValues({
        settings: dataSettings.minAndMaxAdditional,
        isNominated,
        activeIncisionSum: lineAndBarData.activeIncisionSum,
      }),
    [dataSettings.minAndMaxAdditional, lineAndBarData.activeIncisionSum, isNominated],
  );

  const yAxisValue = useMemo(() => {
    const { minValue, maxValue, maxIndicatorLength } = minAndMaxValues;
    const {
      minValue: minValueAdditional,
      maxValue: maxValueAdditional,
      maxIndicatorLength: maxIndicatorLengthAdditional,
    } = minAndMaxAdditionalValues;

    return getIndicatorAxisWithGridDimensions({
      axisIndicatorSettings: axisIndicatorSettings,
      axisAdditionalIndicatorSettings: axisAdditionalIndicatorSettings,
      rotateTo90: dataSettings.rotateTo90,
      activeThemeSchema,
      mainIndicator: { max: maxValue, min: minValue, maxStringLength: maxIndicatorLength },
      additionalIndicator: { max: maxValueAdditional, min: minValueAdditional, maxStringLength: maxIndicatorLengthAdditional },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeThemeSchema,
    axisIndicatorSettings,
    axisAdditionalIndicatorSettings,
    dataSettings.rotateTo90,
    minAndMaxAdditionalValues,
    minAndMaxValues,
  ]);

  const axisValues = useMemo(
    () =>
      dataSettings.rotateTo90
        ? { xAxis: yAxisValue.config, yAxis: xAxisValue.config }
        : { xAxis: xAxisValue.config, yAxis: yAxisValue.config },
    [xAxisValue.config, yAxisValue.config, dataSettings.rotateTo90],
  );

  const legendValue = useMemo(
    () =>
      getLegendConfigWithGridDimensions({
        indicators: dataSettings.indicators,
        legendSettings: legendSettings,
        defaultColor: getColorValues(fontColorLegend) || defaultColor,
        colorsLegendData: colorsLegendData,
      }),
    [dataSettings.indicators, legendSettings, getColorValues, fontColorLegend, defaultColor, colorsLegendData],
  );

  const { onDataZoom, zoomValues } = useDataZoom({
    data: lineAndBarData.data,
    legendSettings,
    horizontalZoom,
    verticalZoom,
    dataZoomHorizontalStartAndEnd,
    dataZoomVerticalStartAndEnd,
    onDataZoomHorizontalStartAndEndSettingsChange,
    onDataZoomVerticalStartAndEndSettingsChange,
  });

  const { horizontalSliderXZoomValue, horizontalInsideYZoomValue, verticalInsideXZoomValue, verticalSliderYZoomValue } =
    zoomValues;

  const showSumGridDimensions = useMemo<GridDimensionsInterface>(() => {
    const { isShow, orientation } = dataSettings.showSum;

    const orientationGridDimensions = {
      vertical: { left: 0, right: 0, top: 30, bottom: 0 },
      horizontal: { left: 0, right: 0, top: 15, bottom: 0 },
    };

    return !dataSettings.rotateTo90 && isPile && isShow ? orientationGridDimensions[orientation] : initialDimensions;
  }, [dataSettings.showSum, dataSettings.rotateTo90, isPile]);

  const barGraphGridDimensions = useMemo<GridDimensionsInterface>(
    () => getBarGraphGridDimensions(dataSettings.indicators),
    [dataSettings.indicators],
  );

  const lineGraphGridDimensions = useMemo<GridDimensionsInterface>(() => {
    let isActiveGrid = false;

    dataSettings.indicators.forEach(
      ({
        settings: {
          elementSettings: { type },
          showValue: { isShow, position },
        },
      }) => {
        if (!isActiveGrid) {
          isActiveGrid =
            type === BarAndLineTypesEnum.Line &&
            isShow &&
            (dataSettings.rotateTo90 ? (position as TopAndBottomType) === 'bottom' : (position as TopAndBottomType) === 'top');
        }
      },
    );

    const shiftPosition = dataSettings.rotateTo90 ? 'right' : 'top';

    return isActiveGrid ? { ...initialDimensions, [shiftPosition]: 20 } : initialDimensions;
  }, [dataSettings.indicators, dataSettings.rotateTo90]);

  const gridValue = useMemo<LineAndBarEChartsOption['grid']>(
    () =>
      calculateGridDimension([
        defaultGridDimension,
        legendValue.gridDimensions,
        horizontalInsideYZoomValue.gridDimensions,
        verticalInsideXZoomValue.gridDimensions,
        showSumGridDimensions,
        lineGraphGridDimensions,
        barGraphGridDimensions,
        xAxisValue.gridDimensions,
        yAxisValue.gridDimensions,
      ]),
    [
      legendValue.gridDimensions,
      horizontalInsideYZoomValue.gridDimensions,
      verticalInsideXZoomValue.gridDimensions,
      showSumGridDimensions,
      lineGraphGridDimensions,
      barGraphGridDimensions,
      xAxisValue.gridDimensions,
      yAxisValue.gridDimensions,
    ],
  );

  const tooltipFormatter = createTooltipFormatter({
    formatting,
  });

  const tooltipValue = useMemo<LineAndBarEChartsOption['tooltip']>(
    () => ({
      formatter: tooltipFormatter,
      show: showTips,
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
        crossStyle: {
          color: activeThemeSchema[ColorVarsEnum.Level_4],
        },
      },
    }),
    [showTips, tooltipFormatter, activeThemeSchema],
  );

  const option = useMemo<LineAndBarEChartsOption>(
    () => ({
      textStyle: {
        color: defaultColor,
      },
      series: series as LineAndBarEChartsOption['series'],
      tooltip: tooltipValue,
      legend: colorStrategyType === 'byIndicator' ? legendValue.config : undefined,
      grid: visualisationPaddingsIsShow
        ? {
            top: topPadding,
            bottom: bottomPadding,
            left: leftPadding,
            right: rightPadding,
          }
        : gridValue,
      dataZoom: [
        horizontalSliderXZoomValue.config,
        verticalSliderYZoomValue.config,
        horizontalInsideYZoomValue.config,
        verticalInsideXZoomValue.config,
      ],
      ...(axisValues as DefaultAxisInterface),
    }),
    [
      defaultColor,
      series,
      tooltipValue,
      colorStrategyType,
      legendValue.config,
      visualisationPaddingsIsShow,
      topPadding,
      bottomPadding,
      leftPadding,
      rightPadding,
      gridValue,
      horizontalSliderXZoomValue.config,
      verticalSliderYZoomValue.config,
      horizontalInsideYZoomValue.config,
      verticalInsideXZoomValue.config,
      axisValues,
    ],
  );

  const onChartClick = useCallback(
    (params: CallbackDataParams) => {
      onActiveNextIncisionIdChange({
        id,
        dataSettings,
        events,
        activeIncisionId: dataSettings.activeIncisionId,
        onChange: onActiveIncisionIdChange,
      });
      updateFilter({ selectedValue: params.name, fieldName: activeIncision.data.fieldName });
    },
    [activeIncision.data.fieldName, updateFilter, events, id, dataSettings],
  );

  const onChangeActiveIncision = (activeIncisionId: string | null) => {
    if (enabledFilters && dataSettings.activeIncisionId && activeIncisionId) {
      onDeleteFiltersForDrillDownVisualisation({
        dataSettings,
        enabledFilters,
        lastActiveIncisionId: dataSettings.activeIncisionId,
        nextActiveIncisionId: activeIncisionId,
      });
    }

    activeIncisionId && onActiveIncisionIdChange({ activeIncisionId, id });
  };

  const onEvents = {
    dataZoom: onDataZoom,
    click: onChartClick,
  };

  return (
    <SingleIncisionLayout
      incisions={dataSettings.incisions}
      value={dataSettings.activeIncisionId}
      incisionSelectorPosition={incisionSelectorPosition}
      isDrillDown={!!isDrillDown}
      isVisible={isVisible}
      onChange={onChangeActiveIncision}
    >
      <WrappedReactECharts
        onEvents={onEvents}
        notMerge
        ref={(e) => {
          eChartRef.current = e?.getEchartsInstance();
        }}
        style={{ width: '100%', height: '100%' }}
        option={option}
      />
    </SingleIncisionLayout>
  );
};

export const LineAndBarVisualisation = memo(
  LineAndBarVisualisationComponent,
) as VisualisationOriginInterface<LineAndBarVisualisationType>;
