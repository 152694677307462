import { FilterInterface, FilterType } from 'store/reducers/filters/types';
import { ReactChildrenType } from 'types/global';
import { ContainerSettings } from 'modules/settingsContainer/common/view/ContainerSettings';
import {
  onBorderSettingsChange,
  onDisableDraggingChange,
  onFilterNameSettingsChange,
  onPositionChange,
  onPositionConfigChange,
  onShadowSettingsChange,
  onShowBackgroundChange,
} from 'modules/settingsContainer/common/data/DefaultFilterEventSettings/constants';

import React from 'react';

import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { OverflowPositionSettings } from 'modules/settingsContainer/common/OverflowPositionSettings';
import { onAlwaysOpenChange, onOverflowPositionChange, onPaddingChange } from 'modules/filters/Single/settings/DataTab/constants';
import { InternalPaddingSettings } from 'modules/settingsContainer/InternalPaddingSettings';
import SubSection from 'shared/ui/subSection';
import { ConnectedCellPaddingSettings } from 'types/store';
import { AlwaysOpenSettings } from '../AlwaysOpenSettings';
import { PositionSettings } from 'modules/settingsContainer/common/view/PositionSettings';

interface DefaultFilterDataSettingsProps<Type extends FilterType> {
  data: FilterInterface<Type> & { paddings?: ConnectedCellPaddingSettings };
  children?: ReactChildrenType;
  isOverflow?: boolean;
  modelIdValue: string;
  ButtonParameters: boolean;
  AlwaysOpen?: boolean;
}

export const DefaultFilterEventsSettings = <Type extends FilterType>({
  data,
  isOverflow,
  ButtonParameters,
  AlwaysOpen,
}: DefaultFilterDataSettingsProps<Type>) => {
  const {
    disableDragging,
    name,
    shadowSettings,
    position,
    borderSettings,
    showBackground,
    positionConfig,
    overflowPosition,
    paddings,
    isAlwaysOpen,
  } = data;
  const ParametersButtonss = ButtonParameters;
  return (
    <>
      <ContainerSettings
        positionConfig={positionConfig}
        disableDragging={disableDragging}
        showBackground={showBackground}
        borderSettings={borderSettings}
        shadowSettings={shadowSettings}
        nameSettings={name}
        onExternalPositionChange={onPositionConfigChange}
        onExternalDisableDraggingChange={onDisableDraggingChange}
        onExternalBorderSettingsChange={onBorderSettingsChange}
        onExternalShowBackgroundChange={onShowBackgroundChange}
        onExternalShadowSettingsChange={onShadowSettingsChange}
        onExternalNameSettingsChange={onFilterNameSettingsChange}
        disabledComponentByCondition
      />
      {(isOverflow || paddings || ParametersButtonss) && (
        <MainContainerSettings titleText="Параметры кнопок">
          <SubSection>
            {isOverflow && <OverflowPositionSettings value={overflowPosition} onChange={onOverflowPositionChange} />}
            {paddings && <InternalPaddingSettings value={paddings} onChange={onPaddingChange} />}
          </SubSection>
        </MainContainerSettings>
      )}
      <PositionSettings value={position} onChange={onPositionChange} />
      {AlwaysOpen && <AlwaysOpenSettings value={isAlwaysOpen!} onChange={onAlwaysOpenChange} />}
    </>
  );
};
